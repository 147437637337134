// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bitcoin-chile-js": () => import("./../src/pages/bitcoin-chile.js" /* webpackChunkName: "component---src-pages-bitcoin-chile-js" */),
  "component---src-pages-criptomonedas-chile-js": () => import("./../src/pages/criptomonedas-chile.js" /* webpackChunkName: "component---src-pages-criptomonedas-chile-js" */),
  "component---src-pages-ethereum-chile-js": () => import("./../src/pages/ethereum-chile.js" /* webpackChunkName: "component---src-pages-ethereum-chile-js" */),
  "component---src-pages-fees-js": () => import("./../src/pages/fees.js" /* webpackChunkName: "component---src-pages-fees-js" */),
  "component---src-pages-funnel-js": () => import("./../src/pages/funnel.js" /* webpackChunkName: "component---src-pages-funnel-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-market-simple-js": () => import("./../src/pages/market-simple.js" /* webpackChunkName: "component---src-pages-market-simple-js" */),
  "component---src-pages-programa-de-referidos-js": () => import("./../src/pages/programa-de-referidos.js" /* webpackChunkName: "component---src-pages-programa-de-referidos-js" */),
  "component---src-pages-security-js": () => import("./../src/pages/security.js" /* webpackChunkName: "component---src-pages-security-js" */)
}

